import React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

export default () => {

  library.add(fas)

  return (
     
    <section id="pricing" className='pricingSection'>
        <div className="pricingAnchor"  id="vision"></div>
      <div  style={{height: "inherit"}} className="row backgroundImage">
          <div className={ 'six columns'}>
        
          <div className='pricingTitle'>
              <h1 className="title">WH<img className='visionCircle' alt='redCircle' src='/images/red-circle.svg' /> ARE WE?</h1>
          </div>
          </div>
          <div className={ 'six columns inheritHeight' }>
             
              <div className='content whoAreWeContent'>We are the Business AI leader in the region, bringing complete, custom-made business AI solutions with measurable added value to your business.
                  {/*<div className='visionButtonOuter' style={{marginTop: '40px'}}>*/}
                  {/*    <a   rel="noopener noreferrer" href='mailto: businessai@ineor.si' className="mainButton mail">BE AI SMART WITH INEOR</a>*/}
                  {/*</div>*/}
               
              </div>
          </div>
      </div>
    </section>
  )
}
