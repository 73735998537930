import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useSiteMetadata } from "../hooks/use-site-metadata"

import NavMenu from "./navmenu"

export default () => {

    React.useEffect(()=>{
        const isBrowser = typeof window !== "undefined"
        if (isBrowser) {
            document.addEventListener('scroll' , handleScroll)
        }
        return () => {
            document.removeEventListener('scroll', handleScroll);
        };
    }, [])

    const handleScroll = () => {
        const navbar = document.querySelector('.nav-fixed');
        if (window.scrollY > 0) {
            navbar.classList.add('nav-active');
        } else {
            navbar.classList.remove('nav-active');
        }
    }
    
    return (
        <header className="nav-fixed nav-active">
            <div className="logo">
                <AnchorLink to="/#top">
                    <img width={'120px'} height={'80px'} alt="" src={useSiteMetadata().logo}/>
                </AnchorLink>
            </div>
            <NavMenu/>
        </header>
    )
}
