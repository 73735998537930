import React from "react"
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'

export default () => {

    library.add(fas)

    return (
        <div id="projects">
            <div className="missionAnchor" id="missionAnchor"></div>
            <section id="pricing">
                <div className="row mission">

                    <div className={'twelve columns'}>
                        <div className='projectsTitleDiv'>
                            <h1 className="title" style={{marginTop: 200, textAlign: "center"}}><span
                                style={{whiteSpace: 'nowrap'}}> PR<img className='missionCircle' alt='redCircle'
                                                                       src='/images/red-circle.svg'/>JECTS</span></h1>
                        </div>
                        <div className={'six columns'}>
                            <div id={"pharaon"}>
                                <h1 >Pharaon</h1>
                                <h3>Date of publication: 17.10.2022 </h3>

                            </div>
                            <img className='projectsImage' alt='pharaonMap' src="images/pharaon_map.png"
                                 style={{marginTop: 20, height: "auto", width: 500}}/>
                        </div>
                        <div className={'six columns'}>
                            <div className='contentPharaon' >
                                We are glad to announce that we are part of the Pharaon project! The Pilots for Healthy
                                and Active Ageing is a Horizon 2020 project to improve the dignity, independence, and
                                wellbeing of older adults by providing enhanced smart and active living solutions.
                                👵🏻📲
                                With our risk-management solution “CareRisk”, we were selected to participate in the
                                Slovenian pilot with a clear objective: healthy ageing!
                                More info about the project: <a href={"https://www.pharaon.eu/"}> Here</a>
                                <br/>
                            </div>
                        </div>
                    </div>


                    <div className={'twelve columns'}>
                        <div className={'six columns'}>
                            <h1 style={{marginTop: "80px"}}>The EU is ageing and in need of care – here is how AI can
                                help.
                            </h1>
                            <h3>Date of publication: 9.12.2022 </h3>

                            <img className='projectsImage' alt='elderSymbolic' src="images/elderlyCare.png"
                                 style={{height: "auto", width: 500, marginTop: 40}}/>
                            <img className='projectsImage' alt='careRisk' src="images/carerisk_app.JPG"
                                 style={{marginTop: 20, height: "auto", width: 1000}}/>
                            <img className='projectsImage' alt='pharaonEU' src="images/pharaon_EU.png"
                                 style={{marginTop: 20}}/>
                        </div>

                        <div className={'six columns'}>
                            <div className='contentProjects' >
                                <a href={"https://www.statista.com/statistics/265759/world-population-by-age-and-region/#statisticContainer"}
                                   target="_blank"> According to Statista </a>, Europe has the largest aging population
                                in
                                the
                                world, and this calls for an audit of unmet healthcare needs and a rethink of the way we
                                ensure
                                smart and active living for Europe's ageing population. Advanced ICT solutions enabled
                                by
                                the
                                state-of-the-art artificial intelligence (AI) technologies can make this a reality!
                                <br/>
                                <br/>
                                Demographic changes and the ageing population result in increased number of people who
                                depend on
                                the help and care from others.
                                <br/>
                                <br/>
                                Thus, the elderly care sector is growing steadily and is comprehensively expanding in
                                size
                                and
                                costs. One of the bigger challenges for geriatricians is how to effectively monitor and
                                prevent
                                the loss of functional abilities of elderly people to maintain or improve their quality
                                of
                                life.
                                <br/>
                                <br/>
                                Effective ICT solutions can bridge the gaps between the health caregivers and
                                recipients,
                                focusing on social determinants of health and preventive measures to reduce human and
                                financial
                                burden of chronic diseases and aging-related problems while also providing
                                person-centred
                                care.
                                <br/>
                                <br/>
                                We recognized that the <a href={"https://www.pharaon.eu/"} target="_blank">PHArA-ON
                                project</a>’s
                                platforms provide a good insight into everyday life of the care recipients and allow for
                                automatic detection of different anomalies. With this we can be proactive – we can
                                predict
                                and
                                manage the risks of the elderly not being as physically active as needed in order to
                                retain
                                their health.
                                <br/>
                                <br/>
                                That is why we are honoured to be a part of Horizon’s 2020, The Pilots for Healthy and
                                Active
                                Ageing (PHArA-ON) project, to improve the dignity, independence, and wellbeing of older
                                adults
                                by providing enhanced smart and active living solutions.
                                <br/>
                                <br/>
                                Our solution, CareRisk, will gather data from wearables (smart bands that record vital
                                signs,
                                activity, etc.), indoor environmental sensors (temperature, CO2, etc.) or other
                                accessible
                                data
                                available through the PHArA-ON platform to create personalized models for elderly to
                                keep
                                them
                                in a good shape. CareRisk will send notifications to the elderly via SenLab’s TV system
                                or
                                SeniorsPhone. Alarms and risks along with action plans will be shown in the main
                                management
                                interface to the caregivers - all by keeping the user’s (elderly and their caregivers)
                                needs
                                in
                                the centre of attention. Because at the heart of our solutions there is always a human.
                                <br/>
                                <br/>

                                Six large-scale pilots have already been launched in five European countries: Portugal,
                                Spain,
                                Slovenia, Italy and Netherlands. Our risk-management solution “CareRisk” will be
                                deployed
                                and
                                tested in Slovenia in the upcoming weeks.
                                <br/>
                                <br/>
                                We are proud to be a part of the AI transformation of Europe’s healthcare, starting
                                right
                                here
                                at home, in Slovenia.
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
