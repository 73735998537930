import React from "react"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

export default () => {

  library.add(fas)

  return (
    <section id="pricing">
      <div className="row blackSection">
          <div className={ 'six columns' } >
              <div className={'twelve columns flexColumn'}>
                  <div className="contentSection">
                      <img alt={'circle'} src='images/circle-big.png' style={{width: '24px'}}/>
                      <div className='blackSectionTitleDiv'><h1>Transparent, ethical, inclusive and explainable AI</h1></div>
                      <div className='content'>
                          We are aware that AI should follow regulations and that is why we offer full transparency with additional explanation and analysis during the development. We take special care that our models are not used to discriminate against people but help them get better insights instead. Although some results of the analyses may be difficult to interpret, we use our knowledge of data wrangling, representations and visualizations to explain correlations between data, model parameters and final results.
                      </div>
                  </div>
              </div>
          </div>
          <div className={ 'six columns' } >
              <div className={ 'six columns flexColumn mobileNoPadding' }>
                  <div className="contentSection">
                      <img alt={'circle'} src='images/circle-big.png' style={{width: '24px'}}/>
                      <div className='blackSectionTitleDiv'><h1>Actionable analytics</h1></div>
                      <div className='content'>
                          Show us the data and we will try to extract additional value to improve your solutions, services or ease user experience for your customers.
                      </div>
                  </div>
              </div>
              <div className={'six columns flexColumn mobileNoPadding'}>
                  <div className="contentSection">
                      <img alt={'circle'} src='images/circle-big.png' style={{width: '24px'}}/>
                      <div className='blackSectionTitleDiv'><h1>AI academy</h1></div>
                      <div className='content'>
                          With the help of world-renowned AI experts from academia and industry we designed a complementary learning path for the AI engineers. We provide our own certification scheme that is obligatory for every new employee. Additionally, we prepared additional courses for you so that you can learn as much as possible from the solutions or models we can provide for your domain.
                      </div>
                  </div>
              </div>
              
          </div>
          <div style={{textAlign: "center"}} className={'twelve columns'}>
              <a rel="noopener noreferrer" href='mailto: businessai@ineor.si' className="mainButton mail"><button className="buttonWhite">Do you want to know what you can gain using your data? Contact us</button></a>
          </div>
      </div>
        <div className="blackSection" style={{paddingTop: '50px !important'}}>
        <div className='smallCircles'></div>
        </div>
    </section>
  )
}
