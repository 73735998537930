import React, {useState} from "react"
import scrollTo from "gatsby-plugin-smoothscroll"
import Scrollspy from "react-scrollspy"

import {useSiteMetadata} from "../hooks/use-site-metadata"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBars} from '@fortawesome/free-solid-svg-icons'

export default function () {
    const [isVisible, setVisibility] = useState(false);
    const {menuItems} = useSiteMetadata()


    let showStyle = null
    if (isVisible) {
        showStyle = {display: 'block', transform: 'scaleY(1)'}
    } else {
        showStyle = {display: 'none'}
    }

    // useEffect(() => {
    //     window.document.addEventListener("click", ev => {
    //         const isDropdownButton = ev.target.matches("[data-dropdown-button]")
    //         if (!isDropdownButton && ev.target.closest("[data-dropdown]") !== null) return
    //
    //         let currentDorpodwn;
    //         if (isDropdownButton) {
    //             currentDorpodwn = ev.target.closest("[data-dropdown]")
    //             currentDorpodwn.classList.toggle("active")
    //         }
    //
    //         window.document.querySelectorAll("[data-dropdown].active").forEach(dropdown => {
    //             if (dropdown === currentDorpodwn) return
    //             dropdown.classList.remove("active")
    //         })
    //     })
    // },[]);

    return (
        <nav id="nav-wrap">
            <button
                id="toggle-btn"
                title="Menu"
                onClick={() => setVisibility(!isVisible)}
            >
                <FontAwesomeIcon icon={faBars}/>
            </button>

            <Scrollspy
                className="nav mobile"
                style={showStyle}
                items={menuItems.map(a => a.path)}
                currentClassName="current"
                offset={300}>

                {menuItems.map((value, index) => {
                    return (
                        <li key={index}>
                            {/*{*/}
                            {/*    value?.path === "projects" ?*/}
                            {/*        <div className="projectsDropdown" data-dropdown>*/}
                            {/*            <button className="link" style={{border: "none"}} data-dropdown-button>*/}
                            {/*                {value?.label}*/}
                            {/*            </button>*/}
                            {/*            <div className="dropdown_menu">*/}
                            {/*                <div id="projectsList">*/}
                            {/*                    {projects?.map((project) => (*/}
                            {/*                        <a style={{padding:10, marginRight:0}} className="dropdown" onClick={() => {*/}
                            {/*                            scrollTo('#' + project.path)*/}
                            {/*                            setVisibility(1)*/}
                            {/*                        }*/}
                            {/*                        }>*/}
                            {/*                            {project.label}*/}
                            {/*                        </a>*/}
                            {/*                    ))*/}
                            {/*                    }*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*        :*/}
                            {value?.path ? <button style={{border: "none"}} onClick={() => {
                                    scrollTo('#' + value?.path)
                                    setVisibility(0)
                                }
                                }>
                                    {value?.label}
                                </button> :
                                <div className='navMenuContactOuter'>
                                    <a className='navMenuContact' onClick={() => {
                                        scrollTo('#contactUs')
                                        setVisibility(0)
                                    }
                                    }>
                                        CONTACT US
                                    </a>
                                </div>
                            }
                        </li>
                    )
                })}
            </Scrollspy>
        </nav>
    )
}
