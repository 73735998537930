import React from "react"
export default () => (
  <section id="hero">
    <div className="row">
      <div className="twelve columns">
        
        <div className="hero-text">
            <h1 className="responsive-headline"> Real-world<br/> business AI<br /> soluti<img className='redCircle' alt='redCircle' src='/images/red-circle.svg' />ns <br/></h1>
        </div>
        
      </div>
    </div>
  </section>
)
