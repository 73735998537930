import React from "react"

export default () => {
  return (
    <div className="article-container">
      <h1>ARTIFICIAL INTELLIGENCE AS A BUSINESS SERVICE</h1>

      <div className="article-text">
        Data is the core of every system, whether you are a small “home-office” company or large enterprise. In every case, you generate data at every step of your business processes or decisions you make. Why not take leverage of this hidden knowledge?

        The core of Data Science lies in efficient data representation and transformation for further re-use. You might think that just gathering data is enough to run sophisticated AI approaches. It may sound OK, but the quality of the result is unbreakably linked to the quality of input data. Successful development and deployment of AI solution as a business solution demands best possible knowledge of all business data, processes, demands, knowledge and other systems of the organization or business - and of course results that should be achieved.

        That is why, in your collaboration with Ineor AI team, we’ll integrate our teams to teach you how to work with the data, reorganize your data lakes, and in the last step turn them into measurable business results. To achieve best possible results, we integrate our team into your organization and processes to observe your whole data economy and provide solutions tailor made for you. These solutions are not limited to in-house analytics - the knowledge and insights of your products or services on the market can give you lots of implicit findings.
      </div>
    </div>
  )
}
