import React from "react"

export default () => {

    React.useEffect(() => {
        initializeHubspotForm();
    }, []);

    const initializeHubspotForm = async () => {
        if ('hbspt' in window) { // Just to make sure the script is loaded (you can also do it by attaching an `onload` event to the script but I did it in this way
            await window.hbspt.forms.create({
                region: "eu1",
                portalId: "25577968",
                formId: "670dc98e-2498-49f5-85f2-1906f7725077",
                target: "#form-container"
            });

        } else {
            setTimeout(initializeHubspotForm, 500)
        }
    }

    return (
        <section id="pricing">
            <div className="subscribeAnchor" id="contactUs"></div>
            <div className="row subscribe">
                <div className={'twelve columns'}>
                    <div className="contentSection">

                        <div className='subscribeOuter'>
                            <h1 className="contactUs">C<img className='contactRedCircle' alt='redCircle'
                                                            src='/images/red-circle.svg'/>NTACT US</h1>
                            <div style={{textAlign: 'center'}}>
                                <a rel="noopener noreferrer" href='mailto: businessai@ineor.si'
                                   className="mainButton mailTo">
                                    <button style={{marginTop: '0px'}}
                                            className={"buttonWhite"}>businessai[AT]ineor.si
                                    </button>
                                </a>
                                <div className={'formOuter'}>
                                    <div id="form-container"></div>
                                </div>


                            </div>

                        </div>

                    </div>
                </div>

            </div>

            <div className="subscribe">
                <div className='smallCircles'>
                    <div className='footerLinks'>
                        <a rel="noopener noreferrer" style={{marginRight: '42px'}} target='_blank'
                           href='https://ineor.si/privacy-policy.html'>Privacy Policy</a>
                        <a rel="noopener noreferrer" target='_blank' href='https://ineor.si/legal-notice.html'>Legal
                            Notice</a>
                    </div>
                    <span className='copyright'>Copyright - Ineor 2022</span>
                </div>
            </div>

        </section>
    )
}
