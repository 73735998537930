import React from "react"

import Layout from "../components/layout"

import Hero from "../components/hero"
import Features from "../components/features"
import Pricing from "../components/pricing"
import Video from "../components/video"
import Mission from "../components/mission"
import Solutions from "../components/solutions"
import BlackSection from "../components/blackSection"
import BusinessAreas from "../components/businessAreas"
import Subscribe from "../components/subscribe"
import Article from "../components/article"
import Projects from "../components/projects";
import FooterSponsors from "../components/footerSponsors"
import {Link} from "react-head"
import {Helmet} from "react-helmet";
import { useSiteMetadata } from "../hooks/use-site-metadata"

export default () => {
  const { sections } = useSiteMetadata()
  const availableSections = {
    "hero": Hero,
    "features": Features,
    "pricing": Pricing,
    "article": Article,
    "video": Video,
    "mission": Mission,
    "solutions": Solutions,
    "blackSection": BlackSection,
    "businessAreas": BusinessAreas,
    "projects": Projects,
    "subscribe": Subscribe
  }

  return(
    <>
      <Helmet>
          <script
              src="https://code.jquery.com/jquery-3.3.1.min.js"
              integrity="sha256-FgpCb/KJQlLNfOu91ta32o/NMZxltwRo8QtmkMRdAu8="
              crossOrigin="anonymous"
          />
        <script charSet="utf-8" type="text/javascript" src="//js-eu1.hsforms.net/forms/shell.js"></script>
      </Helmet>
      <Link href="https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Barlow+Semi+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
            rel="stylesheet" />
      <Layout>
        { sections.map((section, index) => {
          let Tagname = availableSections[section]
          return <React.Fragment key={index}><Tagname  /></React.Fragment>
        }) }
      </Layout>
      {/*<FooterSponsors></FooterSponsors>*/}
    </>
  )
}
