import React from "react"

export default () => (

    <section id='features'>
        <div className="featuresAnchor"  id="businessAI"></div>
        <div className={'row feature'}>
            <div className={'six columns'}>
                <div className='content'>
                    Implementing Artificial Intelligence into your current business applications or creating new, AI- based solutions for your business problems is currently the easiest and most cost-effective way to gain massive competitive advantage on your way to your business success.
                    <br />
                    <br />
                    Productization of state-of-the-art Artificial Intelligence technologies is an
                    exciting task with incredible opportunities for expansion, innovation and impact on your business.
                    With access to enormous amounts of data along with facilitation of algorithmic and hardware
                    advancements we are transferring AI from research and academic sphere to business, improve your
                    existing processes, increasing added value and improving your business bottom-line creating more
                    profit.
                    
                </div>
            </div>
        </div>

            <div className='circles'></div>
       

    </section>
)
