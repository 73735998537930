import React from "react"
import play from '../styles/img/play.png';
import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -40%)',
        width: '80%',
        height: '70%',
        padding: 0
    },
};
export default () => {

    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
        document.body.style.overflow = 'hidden';
    }



    function closeModal() {
        setIsOpen(false);
        document.body.style.overflow = 'unset';
    }

    return (
        <div>
            <section id="pricing" className='videoSection' >
                <div aria-hidden="true"   onClick={openModal} style={{height: "inherit"}} className="video">
                    <div style={{height: "inherit"}} className="row backgroundImage">
                        <img alt={""} src={play} />
                    </div>
                </div>

            </section>
            <Modal
                isOpen={modalIsOpen}
                ariaHideApp={false}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="INEOR BUSINESS AI"
            >
                <div className='iframeOuter' >
                    <iframe
                        title={"INERO BUSINESS AI"}
                        width="100%" height="inherit"
                        src="/images/INEOR_30s_1080p format.mp4"
                        frameBorder="0" allowFullScreen>

                    </iframe>
                </div>
                      
            </Modal>
        </div>
       
    )
}
