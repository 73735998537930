import React from "react"
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'

export default () => {

    library.add(fas)

    return (
        <div id="solutions">
            <div className="solutionsAnchor" id="solutionsAnchor"></div>
            <section id="pricing">

                <div className="row solutions">
                    <div className={'twelve columns'}>
                        <div className='solutionsTitleDiv'>
                            <h1 className="title" >INE<img className='solutionsCircle' alt='redCircle' src='/images/red-circle.svg'/>R BUSINESS AI <br/> SERVICES AND <span style={{whiteSpace:'nowrap'}}>SOLUTI<img className='solutionsCircle' alt='redCircle' src='/images/red-circle.svg'/>NS</span></h1>

                 
                            {/*<h1 style={{top: '70px'}} className="title">INE<img className='solutionsCircle'*/}
                            {/*                                                    alt='redCircle'*/}
                            {/*                                                    src='/images/red-circle.svg'/>R</h1>*/}
                            {/*<h1 className="title">SOLUTI<img className='solutionsCircle' alt='redCircle'*/}
                            {/*                                 src='/images/red-circle.svg'/>NS</h1>*/}
                        </div>
                        <div className={'six columns mobileNoPadding'}>
                            <div style={{marginBottom: '40px'}}><h2 style={{textTransform: 'uppercase'}}>Artificial
                                Intelligence as a business service</h2></div>
                            <div className='contentSolutions'>
                                Data is the core of every system, whether you are a small “home-office” company or large
                                enterprise. In every case, you generate data at every step of your business processes or
                                decisions you make. Why not take leverage of this hidden knowledge?
                                <br/>
                                <br/>
                                The core of Data Science lies in efficient data representation and transformation for
                                further re-use. You might think that just gathering data is enough to run sophisticated
                                AI approaches. It may sound OK, but the quality of the result is unbreakably linked to
                                the quality of input data. Successful development and deployment of AI solution as a
                                business solution demands best possible knowledge of all business data, processes,
                                demands, knowledge and other systems of the organization or business - and of course
                                results that should be achieved.
                                <br/>
                                <br/>
                                That is why, in your collaboration with Ineor AI team, we’ll integrate our teams to
                                teach you how to work with the data, reorganize your data lakes, and in the last step
                                turn them into measurable business results. To achieve best possible results, we
                                integrate our team into your organization and processes to observe your whole data
                                economy and provide solutions tailor made for you. These solutions are not limited to
                                in-house analytics - the knowledge and insights of your products or services on the
                                market can give you lots of implicit findings.
                            </div>
                        </div>
                        <div className={'six columns solutionsMobileMargin mobileNoPadding'}>
                            <div style={{marginBottom: '40px'}}><h2>WORKING TOGETHER!</h2></div>
                            <div className='contentSolutions'>
                                After our work together you will see positive results of using our business AI solutions
                                for some of your specific problems, and then we’ll continue to help you on more and more
                                of your wishes and challenges. During the collaboration we’ll strive to support all of
                                your products and domains you are working with and in. Our collaboration is open, and
                                your team’s knowledge will be growing during our cooperation. After we achieve the point
                                of you being independent player in the business AI field, we can help you set up your
                                own business AI innovation center.
                                <br/>
                                <br/>

                                Our goal is not just to provide a black-box AI solutions for you and then leave. Success
                                for us is to first analyze your AI problems (or find business areas in which you could
                                us them for the first time), so you can see what can be done, and then work together
                                with you to
                                <p style={{paddingLeft: "20px"}}>
                                    <ul className='solutionsParagraphList'>
                                        <li>set up your own independent AI teams and develop solutions together or,</li>
                                        <li> develop and deploy business AI solutions for you and educate on your tem
                                            not only on
                                            their internal workings, but also how to maintain and improve them (with our
                                            help, if
                                            necessary).
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>

                        <div style={{textAlign: "center"}} className={'twelve columns mobileNoPadding'}>
                                <a rel="noopener noreferrer" href='mailto: businessai@ineor.si'
                                   className="mainButton mailBlack"> <button>BE AI SMART WITH INEOR  </button>
                                </a>
                        </div>
                            
                          
                    </div>
                    <div className={'twelve columns'}>
                        <div className={'six columns mobileNoPadding'}>
                            <div><h2>WHAT DO WE OFFER? </h2></div>
                            <div className={'six columns noPadding mobileNoPadding'}>
                                <div className='content'>
                                    <ul>
                                        <li>
                                            <div>Consulting and analysis of your data, processes, products and needs to
                                                find
                                                optimal business AI solutions bringing added value to your business
                                                producing and implementing business AI solutions by our team solving
                                                more
                                                complex demands with integrated teams, producing up to 5 small AI
                                                solutions
                                                (enterprise package)
                                            </div>
                                        </li>
                                        <li>
                                            <div>Long term cooperation with complete business AI ecosystem for
                                                enterprise
                                            </div>
                                        </li>
                                        <li>
                                            <div>Long term cooperation with knowledge transfer to your team</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={'six columns noPadding mobileNoPadding'}>
                                <div className='content'>
                                    <ul>

                                        <li>
                                            <div>Long term cooperation and set up of your own AI center</div>
                                        </li>
                                        <li>
                                            <div>Business AI upgrade of existing technologies/solutions</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className={'six columns mobileNoPadding ourTeam'}>
                            <div><h2>WHAT CAN OUR TEAM DO FOR YOU? </h2></div>
                            <div className={'six columns noPadding mobileNoPadding'}>
                                <div className='content'>
                                    <ul>
                                        <li>
                                            <div>Teach you how to work with data and recognize your data lakes</div>
                                        </li>
                                        <li>
                                            <div>Observe your whole data economy and provide solutions for you</div>
                                        </li>
                                        <li>
                                            <div>Strive to support all of your products and domains you are working with
                                                and
                                                in
                                            </div>
                                        </li>
                                        <li>
                                            <div>Your teams knowledge is growing together with ours</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={'six columns noPadding'}>
                                <div className='content '>
                                    <ul>
                                        <li>
                                            <div>Achieve the point of you being independent player in the AI field,
                                                we’ll
                                                help you set up your own AI innovation center
                                            </div>
                                        </li>
                                        <li>
                                            <div>Set up your own independent AI teams or at least being there for you
                                                for constant support to your teams.
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div style={{textAlign: "center"}} className={'twelve columns mobileNoPadding'}>
                                <a rel="noopener noreferrer" href='mailto: businessai@ineor.si'
                                   className="mainButton mailBlack"><button>Contact us and we’ll find your AI solution together</button>
                                </a>
                            </div>
                    </div>
                    
                </div>
            </section>
        </div>
    )
}
