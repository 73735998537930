import React from "react"
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'

export default () => {

    library.add(fas)

    return (
        <div id="mission">
            <div className="missionAnchor" id="missionAnchor"></div>
            <section id="pricing">
                <div className="row mission">
                    <div className={'six columns'}>

                        <div className='missionTitleDiv'>
                            <h1 className="title">WHAT DO WE <span style={{whiteSpace:'nowrap'}}>D<img className='missionCircle'  alt='redCircle'
                                                                         src='/images/red-circle.svg'/>?</span></h1>
                            <img className='missionImage' alt='mission' src="images/mission.webp"/>
                        </div>
                    </div>
                    <div className={'six columns'}>
                        <div className={'six columns mobileNoPadding'}>
                            <div className='content missionContent'>
                                <ul>
                                    <li>
                                        <div>Transform and accelerate innovation and digitalization of business with the help of AI.
                                        </div>
                                    </li>
                                    <li>
                                        <div>Develop new talent capable of deploying high level knowledge of AI technologies to real world, business applications.
                                        </div>
                                    </li>
                                    <li>
                                        <div>Facilitate national and international cooperation in business AI.</div>
                                    </li>
                                    <li>
                                        <div>Tackle societal challenges with the use of AI technologies</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={'six columns mobileNoPadding'}>
                            <div className='content missionContent'>
                                <ul>
                                    <li>
                                        <div>Design and implement explainable, AI solutions bringing added value and increasing revenues and profit.
                                        </div>
                                    </li>
                                    <li>
                                        <div>Connect academia and businesses to provide state-of-the-art solutions to answer the needs of business & society.
                                        </div>
                                    </li>
                                    <li>
                                        <div>Promote ethical, transparent and inclusive AI.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}
