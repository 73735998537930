import React from "react"
import content from "../../content/screenshots.yaml"

export default () => {
    const screenshots = content.screenshots

    return (
        <div id="areas">
            <div className="areasAnchor" id="areasAnchor"></div>
            <section id="pricing">
                <div className="row solutions">
                    <div className={'twelve columns'}>

                        <div className='businessAreasTitleDiv' >
                            <h1 className="title">BUSINESS</h1>
                            <h1 style={{top: '70px'}} className="title">AREAS</h1>
                            <img className='areasRedCircle'  alt='redCircle' src='/images/red-circle.svg'/>
                        </div>
                    </div>
                    <div className={'twelve columns mobileNoPadding'}>
                        <div id="screenshots-wrapper" className="bgrid-quarters s-bgrid-thirds cf">

                            {screenshots.map((screenshot, index) => {
                                return (
                                    <div className="columns item" key={index.toString()}>
                                        <div className="item-wrap" style={{backgroundColor: screenshot.color}}>
                                            <div className='areaItemDiv'>
                                                <img alt='business icon' src={screenshot.big} width={screenshot.width} height={screenshot.height}  />
                                                <div>{screenshot.caption}</div>
                                            </div>

                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
